<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a
        class="nav-link"
        :class="{ active: selectedView === 'object', 'inactive-tab': selectedView !== 'object' }"
        @click="select('object')"
        href="#"
      >Object</a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        :class="{ active: selectedView === 'json', 'inactive-tab': selectedView !== 'json' }"
        @click="select('json')"
        href="#"
      >JSON</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'OrderDetailTab',
  emits: ['click'],
  data() {
    return {
      selectedView: 'object'
    };
  },
  methods: {
    select(view) {
      this.selectedView = view;
      this.$emit('click', view);
    }
  }
};
</script>

<style scoped>
.inactive-tab {
  color: darkblue;
}

.inactive-tab:hover {
  color: #06C;
}
</style>