import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import * as VueRouter from 'vue-router';

import OrdersPage from './pages/OrdersPage.vue'
import InvoicesPage from './pages/InvoicesPage.vue'
import OrderBevestigingenPage from './pages/OrderBevestigingenPage.vue'
import LoginPage from './pages/LoginPage.vue'
import NotFoundPage from './pages/NotFoundPage.vue'
import OrderDetailPage from './pages/OrderDetailPage.vue'
import InvoiceDetailPage from './pages/InvoiceDetailPage.vue'
import LogViewerPage from './pages/LogViewerPage.vue'
import axios from 'axios';
import localDB from './utils/localDB';

axios.interceptors.request.use(async (config) => {
  const token = await localDB.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


createApp(App)
  .use(VueRouter.createRouter({
    history: VueRouter.createWebHistory(process.env.BASE_URL),
    routes: [{
      path: '/login',
      component: LoginPage,
    }, {
      path: '/orders',
      component: OrdersPage,
    }, {
      path: '/invoices',
      component: InvoicesPage,
    }, {
      path: '/orderDetail/:id',
      name: 'OrderDetail',
      component: OrderDetailPage,
      props: true,
    }, {
      path: '/invoiceDetail/:id',
      name: 'InvoiceDetail',
      component: InvoiceDetailPage,
      props: true,
    }, {
      path: '/orderBevestigingen',
      component: OrderBevestigingenPage,
    }, {
      path: '/logViewer',
      component: LogViewerPage,
    }, {
      path: '/',
      component: LoginPage,
    }, {
      path: '/:pathMatch(.*)*',
      component: NotFoundPage,
    }]
  })).mount('#app')

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
      registration.update(); // Forceer een update
    }).catch(error => {
      console.error('Service Worker registration failed:', error);
    });
  });
}

