<template>
  <div class="editable-json-container">
    <textarea v-model="jsonContent" :disabled="!editable" class="form-control" rows="25"></textarea>
  </div>
</template>

<script>
export default {
  name: 'EditableJsonView',
  props: ['initialJson', 'editable'],
  emits: ['updateJson'],
  data() {
    return {
      jsonContent: JSON.stringify(this.initialJson, null, 2),
    }
  },
  methods: {
    emitUpdate() {
      try {
        const parsedJson = JSON.parse(this.jsonContent);
        this.$emit('updateJson', parsedJson);
      } catch (error) {
        console.error('Ongeldige JSON:', error);
      }
    }
  },
  watch: {
    jsonContent() {
      this.emitUpdate();
    }
  }
}
</script>

<style scoped>
.editable-json-container {
  position: relative;
}
</style>